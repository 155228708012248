import "styles/pages/text-page.scss"

import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const EnglishPage = ({ data }) => {
  const title = "English"

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="text-page-main">
        <div className="container">
          <div className="row justify-content-center no-gutters">
            <div className="col-xl-8">
              <h1 className="text-page-main__title">{data.wpPage.title}</h1>
              <div className="row">
                <div
                  className={
                    data.wpPage.acfMbm.contentRight ? "col-md-6" : "col-md-12"
                  }
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.wpPage.acfMbm.contentLeft,
                    }}
                  />
                </div>
                {data.wpPage.acfMbm.contentRight && (
                  <div className="col-md-6">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.wpPage.acfMbm.contentRight,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoxMjIw" }) {
      id
      title
      acfMbm {
        contentLeft
        contentRight
      }
    }
  }
`

export default EnglishPage
